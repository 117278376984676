import React from "react";
import './index.css'
import {Message} from "@/components/Messsage";

export interface ZCopyProps {
    btn?: React.ReactNode
    text?: string
    tip?: string
}

const ZCopy = (props: ZCopyProps) => {
    const {btn = '复制', text, tip = '复制成功'} = props

    const onCopy = () => {
        const input: any = document.getElementById("zc-copy-input");
        input.value = text; // 修改文本框的内容
        input.select(); // 选中文本
        document.execCommand("copy"); // 执行浏览器复制命令
        Message.success(tip)
    }

    return (
        <>
            <div className='dib' onClick={onCopy}> {btn} </div>
            <textarea id="zc-copy-input"/>
        </>
    )
}

export default ZCopy